import React from 'react'
import Layout from '../../../layouts/Layout';
import SafariDetect from '../../elements/SafariDetect'
import Hero from './Hero'
import Rules from './Rules'

const HouseRules = () => {
	return (
		<Layout>
			<SafariDetect>
				<Hero/>
			</SafariDetect>
			<SafariDetect>
				<Rules/>
			</SafariDetect>
		</Layout>
	)
}

export default HouseRules
