import React, {useEffect, useState} from 'react'
import Video from '../../elements/Video'

const Hero = () => {
	const [pageLoaded, setPageLoaded] = useState(false)

	useEffect(() => {
		setPageLoaded(true)

		return () => setPageLoaded(false)
	},[])

	return (
		<section className="rules__hero">
			<Video
				autoPlay
				playsInline
				muted
				className="show-mob"
				type="mp4"
				src="https://player.vimeo.com/progressive_redirect/playback/777305799/rendition/1080p/file.mp4?loc=external&signature=7c4dd0c723a49f38b4ba4816380ca1a6cea34f443cc91ca16cb8e85bd294de2d"
			/>
			<Video
				autoPlay
				playsInline
				muted
				className="show-desk"
				type="mp4"
				src="https://player.vimeo.com/progressive_redirect/playback/777305776/rendition/1080p/file.mp4?loc=external&signature=685ab4e673059643e3c647eb59919fb865ba626efc86fa2a8087a49e611d6b0c"
			/>
			<div className="rules__hero--content">
				<div className={`text-words-wrapper${pageLoaded ? ' loaded' : ''}`}>
					<span className="text-words">We</span>
					<span className="text-words">live</span>
					<span className="text-words">by</span>
					<span className="text-words">our</span>
					<span className="text-words">rules,</span>
				</div>
			</div>
		</section>
	)
}
export default Hero
