import React, {useEffect} from 'react';
import Seo from '../config/seo';
import BaseLayout from '../layouts/BaseLayout';
import HouseRules from '../components/pages/house-rules';

const OurRulesPage = () => {
    useEffect(() => {
        document.body.style.height = ``
    }, []);
    return (
        <>
            <Seo
                title="Our Rules"
                description="The rules we live by"
            />
            <BaseLayout>
                <HouseRules/>
            </BaseLayout>
        </>
    )
}

export default OurRulesPage;
