import React, {useEffect, useState, useRef} from 'react'
import {gsap, ScrollTrigger} from 'gsap/all'
import {useMediaQuery} from '@react-hook/media-query'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation, EffectFade, Controller} from 'swiper'

import rulesInfo from '../../../jsons/our-rules.json'

gsap.registerPlugin(ScrollTrigger)

const Rules = () => {
	const rulesContainer = useRef(null)
	const slidePrev = useRef(null)
	const slideNext = useRef(null)
	const [index, setIndex] = useState(0)
	const [scrollStop, setScrollStop] = useState(false)
	const [documentClass, setDocumentClass] = useState(false)
	const mediaQuery = useMediaQuery('(min-width: 1024px)')
	const [rulesSlider, setRulesSlider] = useState(false)
	const [controlledSwiper, setControlledSwiper] = useState(null)
	const [controlledSwiper2, setControlledSwiper2] = useState(null)

	useEffect(() => {
		document.addEventListener('keydown', keyboardControl)

		return () => {
			document.removeEventListener('keydown', keyboardControl)
		}
	}, [index])

	useEffect(() => {
		if (typeof window !== 'undefined' && window.innerWidth <= 1279.8) {
			setRulesSlider(true)
		} else {
			setRulesSlider(false)
		}
	},[])

	useEffect(() => {
		if (typeof window !== 'undefined' && window.innerWidth >= 1280) {
			ScrollTrigger.create({
				trigger: rulesContainer?.current,
				start: 'top top',
				toggleActions: 'play none none reverse',
				scrub: true,
				pin: true,
				onEnter: () => {
					setDocumentClass(true)
				},
				onEnterBack: () => {
					setDocumentClass(true)
				},
			})
		}
	}, [])

	useEffect(() => {
		if (documentClass && mediaQuery) {
			document.body.style.overflow = 'hidden'
		} else {
			ScrollTrigger.enable()
			document.body.style.overflow = ''
		}

		return () => {
			document.body.style.overflow = ''
		}
	}, [documentClass, mediaQuery])

	const keyboardControl = e => {
		if (e.keyCode === 38) {
			changeScreen(index - 1)
		}
		if (e.keyCode === 40) {
			changeScreen(index + 1)
		}
	}

	const current = rulesInfo[index]

	const changeScreen = newIndex => {
		if (newIndex >= 0 && rulesInfo[newIndex]) {
			!documentClass && setDocumentClass(true)
			setIndex(newIndex)
		} else {
			setDocumentClass(false)
		}
		setTimeout(() => {
			setScrollStop(false)
		}, 2000)
	}

	const fingerClick = newIndex => {
		if (rulesInfo[newIndex]) {
			if (newIndex > index) {
				setIndex(prev => prev + 1)
			} else {
				setIndex(prev => prev - 1)
			}
		}
	}

	const handleWhell = e => {
		if (mediaQuery && documentClass && !scrollStop) {
			setScrollStop(true)
			if (e.deltaY > 0) {
				changeScreen(index + 1)
			} else {
				changeScreen(index - 1)
			}
		}
	}

	const finishReading = () => {
		setDocumentClass(false)
		ScrollTrigger.disable()
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}

	return (
		<>
			{!rulesSlider ? (
				<section
					className="rules__wrapper dark-section"
					style={{background: current?.color}}
					ref={rulesContainer}
					onWheel={handleWhell}
				>
					<div className="rules__container">
						<div className="rules__content--wrapper">
							{rulesInfo.map((item, i) => {
								return (
									<div key={item.id} className="rules__content">
										<div className={`rules__description ${index === i ? 'active' : ''}`}>
											<div>
												<span>{item.id}.</span>
												<h4>
													<div className="line line-bottom"
														 style={{backgroundColor: current?.dashColor}}/>
													{item.title}
												</h4>
											</div>
											<p>{item.desc}</p>
										</div>
									</div>
								)
							})}
						</div>
					</div>
					<img
						src="/svgs/finger-down-rules.svg"
						alt="chevron-down"
						className="finger-down"
						onKeyDownCapture={() => fingerClick(index + 1)}
						onClick={() => fingerClick(index + 1)}
					/>
					<button onClick={finishReading}>Finished Reading</button>
				</section>
			) : (
				<section className="rules__wrapper dark-section">
					<div className="rules__container">
						<div className="rules__container--wrapper">
							<Swiper
								modules={[EffectFade, Controller]}
								slidesPerView={1}
								loop={true}
								draggable={false}
								effect={'fade'}
								fadeEffect={{crossFade: true}}
								controller={{control: controlledSwiper}}
								onSwiper={setControlledSwiper2}
							>
								{rulesInfo.map((elem, i) => {
									return (
										<SwiperSlide key={`background-gradient-${i}`}>
											<div className="rules__wrapper--background"
												 style={{background: elem.color}}/>
										</SwiperSlide>
									)
								})}
							</Swiper>
							<Swiper
								modules={[Navigation, Controller]}
								slidesPerView={1}
								spaceBetween={30}
								speed={800}
								loop={true}
								controller={{control: controlledSwiper2}}
								onSwiper={setControlledSwiper}
								navigation={{
									prevEl: slidePrev?.current,
									nextEl: slideNext?.current
								}}
							>
								{rulesInfo.map((item, i) => {
									return (
										<SwiperSlide key={`rules-${i}`}>
											<div className="rules__content">
												<div className='rules__description'>
													<div>
														<span>{item.id}.</span>
														<h4>
															<div className="line line-bottom"
																 style={{backgroundColor: item.dashColor}}/>
															{item.title}
														</h4>
													</div>
													<p>{item.desc}</p>
												</div>
											</div>
										</SwiperSlide>
									)
								})}
							</Swiper>
							<div className="mob-navigation">
								<img
									src="/svgs/chevron-down.svg"
									alt="chevron-down"
									ref={slidePrev}
								/>
								<img
									src="/svgs/chevron-down.svg"
									alt="chevron-down"
									ref={slideNext}
								/>
							</div>
						</div>
					</div>
				</section>
			)}
		</>
	)
}

export default Rules